.header-home {
  background: #3498db;
}
.item-list {
  border: solid 1px #e6e6e6;
  border-radius: 3px;
  padding-bottom: 2px;
  margin-bottom: 5px;
}
.header-tabs {
  font-size: 11px;
}

.title-item {
  font-weight: bold;
  margin-bottom: 0;
}
.content-item {
  font-style: italic;
  margin-bottom: 3px;
}
.item-info {
  border-bottom: solid 1px #eee;
  padding-bottom: 5px;
  padding-top: 5px;
}
.content-item {
  color: black;
}
.test {
  background: #f2f2f2;
  border-radius: 5px;
  margin-bottom: 5px;
}

.percent {
  color: black;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}

.set-size {
  font-size: 4em;
}
.charts-container:after {
  clear: both;
  content: "";
  display: table;
}
.pie-wrapper {
  height: 1em;
  width: 1em;
  float: left;
  margin: 15px;
  position: relative;
}
.pie-wrapper:nth-child(3n + 1) {
  clear: both;
}
.pie-wrapper .pie {
  height: 100%;
  width: 100%;
  clip: rect(0, 1em, 1em, 0.5em);
  left: 0;
  position: absolute;
  top: 0;
}
.pie-wrapper .pie .half-circle {
  height: 100%;
  width: 100%;
  border: 0.1em solid #3498db;
  border-radius: 50%;
  clip: rect(0, 0.5em, 1em, 0);
  left: 0;
  position: absolute;
  top: 0;
}
.pie-wrapper .label {
  background: black;
  border-radius: 50%;
  bottom: 0.4em;
  color: #ecf0f1;
  cursor: default;
  display: block;
  font-size: 0.25em;
  left: 0.4em;
  line-height: 2.6em;
  position: absolute;
  right: 0.4em;
  text-align: center;
  top: 0.4em;
}
.pie-wrapper .label .smaller {
  color: black;
  font-size: 0.7em;
  padding-bottom: 20px;
  vertical-align: super;
}
.pie-wrapper .shadow {
  height: 100%;
  width: 100%;
  border: 0.1em solid #bdc3c7;
  border-radius: 50%;
}
.pie-wrapper.style-2 .label {
  background: none;
  color: black;
}
.pie-wrapper.style-2 .label .smaller {
  color: black;
}
.pie-wrapper.progress-30 .pie .right-side {
  display: none;
}
.pie-wrapper.progress-30 .pie .half-circle {
  border-color: #3498db;
}
.pie-wrapper.progress-30 .pie .left-side {
  transform: rotate(108deg);
}
.pie-wrapper.progress-60 .pie {
  clip: rect(auto, auto, auto, auto);
}
.pie-wrapper.progress-60 .pie .right-side {
  transform: rotate(180deg);
}
.pie-wrapper.progress-60 .pie .half-circle {
  border-color: #9b59b6;
}
.pie-wrapper.progress-60 .pie .left-side {
  transform: rotate(216deg);
}
.pie-wrapper.progress-90 .pie {
  clip: rect(auto, auto, auto, auto);
}
.pie-wrapper.progress-90 .pie .right-side {
  transform: rotate(180deg);
}
.pie-wrapper.progress-90 .pie .half-circle {
  border-color: #e67e22;
}
.pie-wrapper.progress-90 .pie .left-side {
  transform: rotate(324deg);
}
.pie-wrapper.progress-40 .pie .right-side {
  display: none;
}
.pie-wrapper.progress-40 .pie .half-circle {
  /* border-color: #1abc9c;
   */
  border-color: #3498db;
}
.pie-wrapper.progress-40 .pie .left-side {
  transform: rotate(144deg);
}

.pie-wrapper.progress-0 .pie .right-side {
  display: none;
}
.pie-wrapper.progress-0 .pie .half-circle {
  /* border-color: #1abc9c;
   */
  border-color: #3498db;
}
.pie-wrapper.progress-0 .pie .left-side {
  transform: rotate(360deg);
}

.pie-wrapper.progress-100 .pie {
  clip: rect(auto, auto, auto, auto);
}
.pie-wrapper.progress-100 .pie .right-side {
  transform: rotate(180deg);
}
.pie-wrapper.progress-100 .pie .half-circle {
  border-color: #3498db;
}
.pie-wrapper.progress-100 .pie .left-side {
  transform: rotate(360deg);
}

.pie-wrapper.progress-80 .pie {
  clip: rect(auto, auto, auto, auto);
}
.pie-wrapper.progress-80 .pie .right-side {
  transform: rotate(180deg);
}
.pie-wrapper.progress-80 .pie .half-circle {
  border-color: #3498db;
}
.pie-wrapper.progress-80 .pie .left-side {
  transform: rotate(288deg);
}

.pie-wrapper.progress-75 .pie {
  clip: rect(auto, auto, auto, auto);
}
.pie-wrapper.progress-75 .pie .right-side {
  transform: rotate(180deg);
}
.pie-wrapper.progress-75 .pie .half-circle {
  border-color: #8e44ad;
}
.pie-wrapper.progress-75 .pie .left-side {
  transform: rotate(270deg);
}
.pie-wrapper.progress-95 .pie {
  clip: rect(auto, auto, auto, auto);
}
.pie-wrapper.progress-95 .pie .right-side {
  transform: rotate(180deg);
}
.pie-wrapper.progress-95 .pie .half-circle {
  border-color: #e74c3c;
}
.pie-wrapper.progress-95 .pie .left-side {
  transform: rotate(342deg);
}
.pie-wrapper--solid {
  border-radius: 50%;
  overflow: hidden;
}
.pie-wrapper--solid:before {
  border-radius: 0 100% 100% 0%;
  content: "";
  display: block;
  height: 100%;
  margin-left: 50%;
  transform-origin: left;
}
.pie-wrapper--solid .label {
  background: transparent;
}
.pie-wrapper--solid.progress-65 {
  background: linear-gradient(to right, #e67e22 50%, #34495e 50%);
}
.pie-wrapper--solid.progress-65:before {
  background: #e67e22;
  transform: rotate(126deg);
}
.pie-wrapper--solid.progress-25 {
  background: linear-gradient(to right, #9b59b6 50%, #34495e 50%);
}
.pie-wrapper--solid.progress-25:before {
  background: #34495e;
  transform: rotate(-270deg);
}
.pie-wrapper--solid.progress-88 {
  background: linear-gradient(to right, #3498db 50%, #34495e 50%);
}
.pie-wrapper--solid.progress-88:before {
  background: #3498db;
  transform: rotate(43.2deg);
}
