.button-action {
  height: 25px;
  width: 25px;
  padding-top: 3px !important;
  padding-left: 5px !important;
}

.CalendarDay.CalendarDay__today {
  background-color: #81bef7 !important;
  border-color: #20a8d8 !important;
}

.react-contextmenu {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  color: #373a3c;
  font-size: 16px;
  margin: 2px 0 0;
  min-width: 160px;
  outline: none;
  opacity: 0;
  padding: 5px 0;
  pointer-events: none;
  text-align: left;
  transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
  z-index: 9999;
}

.react-contextmenu-item {
  background: 0 0;
  border: 0;
  color: #373a3c;
  cursor: pointer;
  font-weight: 400;
  line-height: 1.5;
  padding: 3px 20px;
  text-align: inherit;
  white-space: nowrap;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
  color: #fff;
  background-color: #20a0ff;
  border-color: #20a0ff;
  text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
  color: #878a8c;
}

.react-contextmenu-item--divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  cursor: inherit;
  margin-bottom: 3px;
  padding: 2px 0;
}
.react-contextmenu-item--divider:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
  padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
  content: '▶';
  display: inline-block;
  position: absolute;
  right: 7px;
}

.example-multiple-targets::after {
  content: attr(data-count);
  display: block;
}
.image-preview {
  max-height: 180px;
  margin: 0 auto;
}

.clear {
  clear: both;
}

.details,
.title {
  text-align: center;
}

.error {
  margin-top: 4px;
  color: red;
}

.has-error {
  border: 1px dotted red;
}

.image-container {
  align-items: center;
  display: flex;
  width: 85%;
  height: 80%;
  float: left;
  margin: 15px 10px 10px 37px;
  text-align: center;
}

.preview-container {
  height: 230px;
  width: 100%;
  margin-bottom: 40px;
  overflow-x: scroll;
}

.single-preview-container {
  height: 230px;
  width: 100%;
  margin-bottom: 40px;
  overflow-x: hidden;
}

.placeholder-preview {
  cursor: pointer;
}

.placeholder-preview,
.render-preview {
  text-align: center;
  background-color: #efebeb;
  height: 100%;
  width: 100%;
  border-radius: 5px;
}

.upload-container {
  cursor: pointer;
  height: 300px;
}

.icon-only {
  background-position: 0% 50%;
  background-repeat: no-repeat;
  padding-left: 16px;
  display: inline-block;
  width: 0;
  height: 16px;
  overflow: hidden;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 8px;
  vertical-align: middle;
}
.icon-only::after {
  content: '\a0';
}

.icon-download {
  background-image: url('/assets/img/download.png');
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: rgb(237 90 90 / 50%);
  border-color: #e55353;
  opacity: unset;
}