:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #20A8D8;
  // --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  --dark-main-color: #343a40;
  --dark-font-color: #555555;
  --light-font-color: #999999;
  --blue-color: #4390e0;
  --button-color: #69695c;
  --dark-gray-color: #cccccc;
  --light-gray-color: #dddddd;
  --bg-color: #f8f8ff;
  --white-color: #ffffff;
  --white-gray-color: #f8f8ff;
  --red-color: #e26b63;
}

html {
  overflow-x: hidden;
}

/* width */
html::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

/* Track */
html::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
html::-webkit-scrollbar-thumb {
  background: #ccc;
}

/* Handle on hover */
html::-webkit-scrollbar-thumb:hover {
  background: #999;
}

/* width */
.modal-body::-webkit-scrollbar {
  width: 20px;
}

/* Track */
.modal-body::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

/* Handle */
.modal-body::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 3px;
}

/* Handle on hover */
.modal-body::-webkit-scrollbar-thumb:hover {
  background: #999;
}

/* width */
.tableScroll::-webkit-scrollbar {
  width: 20px;
  height: 20px;
}

.tableScrollV2::-webkit-scrollbar {
  width: 20px;
  height: 20px;
}

/* Track */
.tableScroll::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.tableScrollV2::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

/* Handle */
.tableScroll::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 3px;
}

.tableScrollV2::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 3px;
}

/* Handle on hover */
.tableScroll::-webkit-scrollbar-thumb:hover {
  background: #999;
}

.tableScrollV2::-webkit-scrollbar-thumb:hover {
  background: #999;
}

// body{
//     background-color: var(--bg-color);
//     color: var(--dark-font-color);
// }

// .table {
//     color: var(--dark-font-color);
// }

fieldset {
  border: 1px solid #dddddd !important;
  border-radius: 5px;
  padding: 0px 0px 10px 0px;
  margin-left: 10px;
}

legend {
  margin-left: 10px;
}

.c-sidebar {
  // top: 68px !important;
  // background: var(--dark-main-color);
  // color: var(--light-font-color);
}

.custom-header-fixed_showSideBar_notMinimize {
  position: fixed !important;
  top: 0 !important;
  left: 258px !important;
}

.show-menu {
  position: fixed;
  width: 72px;
  height: 70px;
  top: 0px;
  left: 0px;
  background: #3c4b64;
  border: 3px solid #ddd;

  i {
    margin: 21px;
    font-size: 28px;
    color: #5e999b;
  }
}

.show-menu:hover {
  background: #321fdb;
  border: 3px solid #999;

  i {
    margin: 21px;
    font-size: 28px;
    color: #fff;
  }
}

.show-menu:active {
  background-color: #ddd;
  border: none;
}

.c-header {
  // color: var(--blue-color) !important;
  z-index: 1032 !important;
  min-height: 50px;
  transition-duration: 0.3s;
}

.c-header-disable {
  transition-duration: 0.3s;
  top: -75px !important;
}

.space-body {
  height: 0px;
  display: block;
  // margin-bottom: 75px !important;
  margin-bottom: 55px !important;
  transition-duration: 0.3s;
}

.space-body-mini {
  height: 1px;
  display: block;
  margin-bottom: 0px;
  transition-duration: 0.3s;
}

.c-main {
  transition-duration: 0.3s;
}

.c-header-brand {
  margin: 2px -2px 2px 12px !important;
  min-height: 32px;
}

.c-header-toggler {
  min-width: 40px;
  font-size: 25px;
  background-color: transparent;
  border: 0;
  border-radius: 0.25rem;
}

.c-header-brand {
  margin: 8px -2px 4px 12px;
  min-height: 32px;
}

.c-header-nav-link>span {
  // color: var(--blue-color) !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  color: rgb(32, 168, 216) !important;
}

.c-header-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 40px;
  padding: 0;
  margin-bottom: 0;
  list-style: none;
}

.c-header .c-subheader {
  margin-top: -1px;
  box-shadow: -1px 3px 5px #ddd;
  border-top: 1px solid #ddd;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  min-height: 25px;
  font-size: 15px;
  line-height: 14px;
}

.c-subheader-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 25px;
  padding: 0;
  margin-bottom: 0;
  list-style: none;
}

.c-sidebar-brand-minimized {
  margin: 10px -8px 8px 12px !important;
}

.custom-header-fixed_showSideBar_minimize {
  position: fixed !important;
  top: 0;
  // box-shadow: 5px 5px 5px #ddd;
  box-shadow: 0px 3px 5px #ddd;
  height: 50px;
}

.custom-header-fixed_disappearSideBar {
  position: fixed !important;
  top: 0;
  left: 0 !important;
}

.dropdown>.nav-link {
  padding: 0;
}

.custom-footer-fixed_showSideBar_notMinimize {
  position: fixed !important;
  bottom: 0 !important;
  left: 258px !important;
  width: 100%;
}

.custom-footer-fixed_showSideBar_minimize {
  position: fixed !important;
  bottom: 0 !important;
  left: 508px !important;
  width: 100%;
}

.custom-footer-fixed_disappearSideBar {
  position: fixed !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 100%;
}

.custom-content_showSideBar {
  left: 258px !important;
  position: relative;
}

.custom-content_disappearSideBar {
  left: 0 !important;
  position: relative;
}

.modal-backdrop {
  width: 100% !important;
  height: 100% !important;
}

.card {
  transition-duration: 0.3s;
}

.card.card_max {
  min-height: calc(125vh - 6px) !important;
  transition-duration: 0.3s;
}

.fade>div>.row>.col>.card,
.fade>div>div>.row>.col>.card {
  width: calc(125vw - 100px);
  min-height: calc(125vh - 81px);
  margin-bottom: 5px !important;
}

.fade>div>.row>.col>.card,
.fade>div>div>.row>.col>.card {
  width: calc(125vw - 100px);
  min-height: calc(125vh - 81px);
  margin-bottom: 5px !important;
}

.fade>div>.row>.col>.card,
.fade>div>div>.row>.col>.card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(125vw - 85px);
  width: -moz-available;
  min-height: calc(125vh - 81px);
  min-width: 0;
  margin-bottom: 5px;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid;
  border-radius: 0.25rem;
  background-color: #fff;
  border-color: #d8dbe0;
}

.fade>div>.card {
  width: calc(125vw - 120px);
  min-height: calc(125vh - 81px);
  margin-bottom: 5px !important;
}

.fade>div>.row>div>.card {
  width: calc(125vw - 85px);
  min-height: calc(125vh - 81px);
  margin-bottom: 5px !important;
}

@media (min-width: 992px) {
  .fade>div>.row>.col-12>.card {
    width: calc(62.5vw - 84px);
    min-height: fit-content !important;
    margin-bottom: 5px !important;
  }
}

@media (max-width: 765px) {
  main {
    margin-left: -75px !important;
  }
}

.filter-search {
  display: revert !important;
}

.filter-search>div>div:nth-child(2) {
  // position: fixed !important;
}

.table-sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 200 !important;
}

.title-add-user-group-sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 5;
}

.list-group-action-hover:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

// .gantt_task_progress {
//     background-color: #081199 !important;
// }
.gallery-empty {
  color: red;
  text-align: center;
  font-size: 22px;
}

.gallery-empty-wrapped {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-original-gallery {
  height: 550px;
  // width: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.empty-data-gallery {
  background-color: black;
  height: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty-data-gallery {
  img {
    height: 300px;
  }
}

// .image-gallery-thumbnail-image {
//   height: 100px;
// }

.ReactGridGallery_tile {}

.ReactGridGallery_tile-description {
  background: transparent !important;
  height: fit-content !important;
  overflow: hidden;
  position: absolute;
  bottom: 4px;
  font-weight: bold;
  color: black;
  text-align: center;
  text-overflow: ellipsis;
}

@media (min-width: 992px) {
  .c-sidebar-unfoldable:not(:hover).c-sidebar-fixed {
    z-index: 1031;
    width: 72px;
    border: 1px solid #ddd;
  }
}

.c-sidebar-nav-icon {
  flex: 0 0 72px;
  height: 28px;
  font-size: 1.09375rem;
  text-align: center;
  transition: 0.3s;
  // fill: var(--light-font-color);
  margin: 5px;
}

.c-sidebar .c-sidebar-nav-link.c-active .c-sidebar-nav-icon,
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  // color: var(--light-font-color);
  // fill: var(--light-font-color);
}

.c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  // color: var(--light-font-color);
}

.c-sidebar .c-sidebar-nav-link,
.c-sidebar .c-sidebar-nav-dropdown-toggle {
  // color: var(--light-font-color);
  // background: transparent;
}

.c-sidebar .c-sidebar-nav-title {
  // color: var(--light-font-color);
}

.c-sidebar .c-sidebar-nav-link.c-active,
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
  // color: var(--light-font-color);
}

.c-sidebar.c-sidebar-fixed {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1030;
  border: 1px solid #ddd;
}

.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 35px;
  margin-right: auto;
  margin-left: auto;
}

@media (hover: hover),
not all {

  .c-sidebar .c-sidebar-nav-link:hover,
  .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
    // color: var(--white-color);
    // background: var(--blue-color)
  }
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  border-bottom: 1px solid;
  // background-color: var(--white-color);
  border-color: var(--light-gray-color);
}

.c-footer {
  position: fixed;
  display: flex;
  flex: 0 0 72px;
  flex-wrap: wrap;
  align-items: center;
  height: 32px !important;
  padding: 0px 2rem;
  bottom: 0px !important;
}

.table-striped tbody tr:nth-of-type(odd) .fixed-column_showSideBar_minimize {
  background-color: #f2f2f3;
}

.table-striped tr:nth-of-type(even) .fixed-column_showSideBar_minimize {
  background-color: #ffffff;
}

@media (hover: hover),
not all {
  .table-hover tbody tr:hover {
    color: #333;
    // background-color: var(--bg-color)
  }
}

.icon_card {
  font-size: 18px;
  // color: var(--dark-font-color);
}

.title_span {
  font-size: 18px;
  padding: 10px;
  font-weight: 600;
  // color: var(--dark-font-color);
}

.btn {
  min-width: 85px;
  padding: 5px 15px 5px 5px;
  border: none;
  border-radius: 5px !important;
  line-height: 25px;
  font-size: 12pt;
  margin-right: 6px !important;
}

.btn.btn-sm,
.btn.mr-1,
.btn.button-action {
  margin: 2px !important;
  padding: 0 !important;
  width: 35px;
  height: 33px;
  min-width: 0;
}

.btn.btn-sm>i {
  margin-right: 16px;
  height: 20px;
  margin: 0;
}

.btn.mr-1>i {
  height: 0;
  margin: 0;
  padding: 0;
  width: 0;
  left: -8px;
  top: -17px;
}

.btn.button-action>i,
td .btn.btn-warning>i,
td .btn.btn-secondary>i {
  height: 0;
  margin: 0;
  padding: 0;
  width: 0;
  left: -8px;
  top: -17px;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;

  z-index: 2;
}

.form-group {
  margin-bottom: 10px;
}

.form-row>div,
.form-group>div,
.row>div {
  z-index: 3;
}

.modal-body .form-row>div,
.modal-body .form-group>div,
.modal-body .row>div {
  z-index: auto;
}

.modal-body {
  //   max-height: calc(125vh - 190px);
  //   overflow-x: auto;
  //   padding-bottom: 5px;
  //   margin-bottom: 5px;
}

.input-group-text {
  // color: var(--white-color);
  // background: var(--dark-font-color);
}

.btn:hover {
  // background-color: var(--light-font-color);
}

.btn>i {
  color: var(--white-gray-color);
  font-size: 20px;
  margin-right: 26px;
  position: relative;
}

.btn>i.fa-print {
  color: #000000;
}

.btn>i::before {
  position: absolute;
}

.btn.short {
  padding: 0px;
  height: 30px;
}

.search_index>div {
  margin-top: -5px;
  border-radius: 0;
  // color: var(--dark-font-color);
}

.DateInput_input {
  font-weight: 200;
  font-size: 19px;
  line-height: 28px;
  // color: var(--dark-font-color);
  // background-color: var(--red-color);
  width: 100%;
  padding: 11px 11px 9px;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid transparent;
  border-left: 0;
  border-radius: 0;
  font-size: 15px !important;
  padding-left: 40px !important;
}

.modal-primary .modal-header {
  // background-color: var(--blue-color) !important;
}

.table {
  margin-bottom: 10px;
  border-radius: 5px;
}

.tableScroll {
  display: block;
  width: max-content;
  max-width: calc(125vw - 110px);
  overflow: auto;
  max-height: calc(125vh - 210px);
  margin-bottom: 10px;
  transition-duration: 0.3s;
  border-collapse: separate;
  border-spacing: 0;
  // max-height: calc(125vh - 222px);
  // max-height: calc(125vh - 135px);
}

.tableScrollV2 {
  display: block;
  width: max-content;
  max-width: calc(125vw - 110px);
  overflow: auto;
  max-height: calc(125vh - 250px) !important;
  margin-bottom: 10px;
  transition-duration: 0.3s;
  border-collapse: separate;
  border-spacing: 0;
}

.tableCustomScroll {
  display: block;
  width: max-content;
  max-width: calc(125vw - 110px);
  overflow: auto;
  max-height: calc(50vh - 10px);
  margin-bottom: 10px;
  transition-duration: 0.3s;
  border-collapse: separate;
  border-spacing: 0;
  // max-height: calc(125vh - 222px);
  // max-height: calc(125vh - 135px);
}

.tableScroll_max {
  // max-height: calc(125vh - 135px);
  max-height: calc(125vh - 110px);
  transition-duration: 0.3s;
  // max-height: calc(125vh - 148px);
}

.tableScroll_maxV2 {
  max-height: calc(125vh - 175px) !important;
  transition-duration: 0.3s;
}

.tableScrollNav {
  max-height: calc(125vh - 75px) !important;
}

.tableScroll_max.tableScrollNav {
  max-height: calc(125vh - 265px) !important;
}

.tableScroll_maxV2.tableScrollNav {
  max-height: calc(125vh - 265px) !important;
}

.smallTable {
  display: block;
  width: max-content;
  max-width: calc(125vw - 110px);
  overflow: auto;
  max-height: calc(125vh - 341px);
  margin-bottom: 10px;
}

// #ApprovalTableData, #WorkGroupManagementTableData, #DailyTransportPlanManagementTableData {
//   max-height: calc(125vh - 300px) !important;
// }

.tableScroll>thead {
  height: 0;
  margin-bottom: 87px;
  position: sticky;
  top: -1px;
  z-index: 1;
}

.tableScrollThead>thead {
  height: 0;
  margin-bottom: 87px;
  position: sticky;
  top: -1px;
  z-index: 1;
}

.tableScrollV2>thead {
  height: 0;
  margin-bottom: 87px;
  position: sticky;
  top: -1px;
  z-index: 1;
}

.badge {
  display: inline-block;
  padding: 0.4em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid;
  border-color: var(--light-gray-color);
  font-size: 14px;
  font-weight: 500;
}

.table thead th {
  top: 0;
  border-width: 0 1px;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-right: 1px solid;
  border-width: 0 1px;
  box-shadow: inset 0 -1px 0 #ddd, inset 0 0px 0 #ddd;
}

.edit-content {
  color: var(--blue-color);
  text-align: right;
  /* height: 100%; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  outline: none;
  padding: 10px;
}

.fixed-column_showSideBar_minimize {
  position: sticky;
  left: 50px;
  z-index: 0 !important;
  // background: var(--blue-color);
  // color: #FFF;
}

.c-footer {
  display: flex;
  flex: 0 0 72px;
  flex-wrap: wrap;
  align-items: center;
  height: 50px;
  padding: 0px 2rem;
  width: 2328px !important;
}

.c-footer {
  color: var(--blue-color);
  background: var(--white-gray-color);
  border-top: 1px solid #ddd;
  padding-right: 100px;
  position: relative !important;
  bottom: 0px !important;
  width: calc(125vw - 50px) !important;
}

.form-control {
  display: block;
  width: 100%;
  height: 38px;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid;
  // color: #5c6873;
  // background-color: var(--white-color);
  border-color: var(--light-gray-color);
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.button-action {
  padding: 0 10px;
  height: 32px;
  min-width: 45px;
}

.badge {
  font-size: 13px;
}

.custom-control-label::after {
  top: 0.16625rem;
}

.single-preview-container>.row>.col>.btn-danger {
  top: 2px !important;
  right: 15px !important;
}

html:not([dir='rtl']) .custom-control-label::after {
  left: -1.4rem;
}

@media (max-width: 991.98px) {
  .c-sidebar-backdrop {
    display: none;
  }
}

.col-md-3>.form-group>div {
  z-index: 3;

  >div {
    border-color: var(--light-gray-color);
  }
}

html:not([dir='rtl']) .custom-control-label::after {
  left: -1.5rem;
  top: 0.19rem;
}

.customInputDate {
  flex: 0 0 25%;
  max-width: 25%;
}

.customInputSelect {
  flex: 0 0 15%;
  max-width: 15%;

  >div {
    z-index: 3;
  }
}

.customButton {
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;
  flex: auto !important;
  max-width: unset;
  width: auto;
}

.formSearch {
  margin: 10px 0;
}

.card-header {
  padding: 8px 20px 4px 24px;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 10px;
}

.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 28px;
  margin-right: auto;
  margin-left: auto;
}

.page-link {
  padding: 2px 10px;
}

nav ul.pagination {
  margin-bottom: 0 !important;
}

.submitSection {
  height: 30px;
}

.row>.col-9 {
  padding-right: 6px;
}

.row>.col-8 {
  padding-right: 6px;
}

.inputGruopFlex {
  display: flex;
  flex-direction: row;
  justify-content: end;
  flex: auto;
  padding: 0;
  margin: 0;
  width: auto;
  height: auto;
}

.searchTextIcon {
  height: 37px;
}

.DateInput {
  padding: 1px;
}

#AdvancePaymentTableData {
  max-height: calc(125vh - 148px);
}

#QuotationTableData,
#RepairListTableData,
#DriverAdvanceReportTableData {
  max-height: calc(125vh - 178px);
}

#VehicleActivityReportTableData {
  max-height: calc(125vh - 325px);
}

#RevenueCostFollowCarDetailTableData,
#RevenueCostFollowCarSummaryTableData {
  max-height: calc(125vh - 178px);
}

#NewRevenueCostDetailsByCarsTableData,
#TransportReportTableData,
#FuelUsageTableData {
  max-height: calc(125vh - 178px);
}

.image-name-dropzone {
  overflow: hidden;
  text-overflow: ellipsis;
}

.ReactGridGallery_tile-icon-bar div[title='Select'] {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  visibility: visible !important;
}

.header-table-actions-and-paging.input-group {
  justify-content: flex-end !important;
}

html:not([dir='rtl']) .c-sidebar.c-sidebar-light.c-sidebar-right {
  z-index: 1033;
}

.btn-success {
  background-color: #20A8D8 !important;
  /* Màu nền cho tất cả các trạng thái */
  border-color: #20A8D8 !important;
  /* Màu viền đồng bộ */
}

.btn-success:hover,
.btn-success:active,
.btn-success:focus {
  background-color: #20A8D8 !important;
  /* Màu nền giống nhau ở mọi trạng thái */
  border-color: #20A8D8 !important;
  box-shadow: none !important;
  /* Loại bỏ hiệu ứng shadow */
}

.btn-success:focus {
  outline: none !important;
  /* Loại bỏ viền mặc định khi focus */
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #20A8D8;
  border-color: #20A8D8;
  opacity: unset;
  /* z-index: -10; */
}

// .btn.disabled,
// .btn:disabled {
//   opacity: unset;
//   /* z-index: -10; */
// }

//style for card user

.card-user-container {
  justify-content: center;

  .card-user-item {
    background-color: white;
    border-radius: 15px;
    box-shadow: 3px 5px #8b887c;
    margin: 5px;
  }

  .header {
    img {
      width: 70px;
      height: auto;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-title {
      color: #4390e0;
      font-size: 14;
    }

    margin-bottom: 20px;
  }

  .card__avatar {
    img {
      width: 100%;
      height: 200px;
    }

    margin-bottom: 30px;
    border-radius: 5px;
  }

  .user {
    color: #4390e0;
  }

  h5 {
    margin-bottom: 50px;
  }

  .footer {
    border-top: solid 1px #eee;

    h6 {
      font-size: 12px;
    }

    .addess {
      margin-top: 15px;
    }
  }

  .qr {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #0f63ac;

    a:hover {
      cursor: pointer;
    }

    a {
      color: white;
    }
  }

  .info {
    i {
      color: #4390e0;
    }
  }
}

.label-card {
  width: 200px;
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
  margin-left: -15px;
  display: inline-block;
  margin-bottom: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.card-cover {
  padding-bottom: 5px;
  padding-top: 15px;
  margin-top: -35px;
}

// .c-sidebar {
//   background: var(--white-color);
// }

// .c-sidebar .c-sidebar-nav-link.c-active .c-sidebar-nav-icon,
// .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
//   color: var(--dark-main-color);
//   fill: var(--dark-main-color);
// }

// .c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon,
// .c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
//   color: var(--dark-main-color);
// }

// .c-sidebar .c-sidebar-nav-link,
// .c-sidebar .c-sidebar-nav-dropdown-toggle {
//   color: var(--dark-main-color);
//   // background: transparent;
//   background: var(--white-color);
// }

// .c-sidebar .c-sidebar-nav-title {
//   color: var(--dark-main-color);
//   background: var(--white-color);
// }

// .c-sidebar .c-sidebar-nav-link.c-active,
// .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
//   color: var(--dark-main-color);
// }

.custom_loading_suspense {
  flex: 1;
  display: flex;
  height: 80vh;
  justify-content: center;
  align-items: center;
}

.btn-refresh-consiment-container {
  margin-bottom: 13px !important;
  background-color: #f9b115;
  border-color: #d69405;
  padding: 0 !important;
  width: 35px;
  height: 33px;
  min-width: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.btn-refresh-consiment-container:hover {
  background-color: #e29c06;
}

@media (max-width: 1000px) {
  .notify-of-services {
    display: none;
  }
}

.titlePhase {
  font-size: 18px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 5px;
}

.customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.customers td,
.customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

.customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

.customers tr:hover {
  background-color: #ddd;
}

.customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #20a8d8;
  color: white;
}

.ant-tooltip-inner {
  color: white !important;
  background-color: #f9b115 !important;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  border-bottom-color: #f9b115;
}

.resize-table {
  .tableScroll {
    width: auto;
  }
}

.chart-debt {
  .recharts-surface {
    // width: 1800px !important;
  }

  .recharts-responsive-container {
    z-index: 0 !important;
  }
}

.css-11unzgr {
  max-height: 168px !important;
  scroll-behavior: smooth !important;
}

.template_list {
  max-height: 20rem;
  overflow-y: scroll;
}

.active_car_detail {
  background-color: #f5f7fb;
  padding-top: 10px;
  cursor: pointer;
}

.car_detail {
  padding-top: 10px;
  cursor: pointer;
}

.card_button_car_detail {
  // position: absolute;
  // bottom: 10px;
  width: 100%;
}

.container_car_detail {
  height: 100vh;
  background-color: rgb(245, 247, 251);
  border-radius: 20px;
  padding: 20px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cursor-pointer {
  cursor: pointer;
}

.custom-tooltip-place-left {
  margin-left: 350px !important;
  background-color: #f9b115 !important;
}

.image-gallery-slide img {
  max-width: 100%;
  max-height: 80vh;
  object-fit: cover;
}

.image-gallery-slide video {
  min-width: 70%;
  max-width: 100%;
  max-height: 80vh;
}

.image-gallery-thumbnail img,
.image-gallery-thumbnail video {
  width: 100%;
  height: auto;
  object-fit: cover;
}

#scroll-virtualize {
  width: 100%;
}

.custom-c-badge {
  display: flex;
  text-wrap: wrap;
}

.loader-container {
  position: relative;
  width: 100px;
  /* Set to the width of your image */
  height: 100px;
  /* Set to the height of your image */
  display: flex;
  justify-content: center;
  align-items: center;



}

.loading-logo {
  position: absolute;
  // top: 0;
  // left: 0;
  width: 60px;
  height: auto;
  z-index: 1;
  /* Ensure the logo is above the loader */
}

.loader-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 6px solid white;
  /* Light grey border */
  border-top: 6px solid #3498db;
  /* Blue border on top */
  border-radius: 50%;
  /* Circle shape */
  animation: spin 1s linear infinite;
  /* Animation */
  z-index: 2;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
    /* Starting position */
  }

  100% {
    transform: rotate(360deg);
    /* Full circle */
  }
}

.comment-item {
  padding: 5px;
  background: #20A8D8;
  color: white;
  border-radius: 15px;
  width: 75%;
  margin-bottom: 10px;
}

.image-container-print {
  position: relative;
  display: inline-block;

  img {
    width: 100%;
    /* Tùy chỉnh kích thước ảnh */
    height: auto;
  }

  .checkbox {
    position: absolute;
    top: 5px;
    /* Điều chỉnh vị trí checkbox theo chiều dọc */
    left: 5px;
    /* Điều chỉnh vị trí checkbox theo chiều ngang */
    z-index: 1;
    /* Đảm bảo checkbox nằm trên ảnh */
  }
}

.hover-item {
  cursor: pointer;
}